import { render, staticRenderFns } from "./Abouts.vue?vue&type=template&id=81a9f77e&scoped=true&"
import script from "./Abouts.vue?vue&type=script&lang=js&"
export * from "./Abouts.vue?vue&type=script&lang=js&"
import style0 from "./Abouts.vue?vue&type=style&index=0&id=81a9f77e&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "81a9f77e",
  null
  
)

export default component.exports